import fetchAPI from "./fetchAPI"

export async function searchContent(string, context, filters) {
  const { searchContent } = await fetchAPI(
    `query($string: String, $context: searchContext!, $filters: JSON!) {
        searchContent(string: $string, context: $context, filters: $filters) {
          result
          totalCount
          maxPage
          cardType
          hash
        }
      }`,
    {
      variables: { string, context, filters: filters }
    }
  )

  return searchContent
}

export async function tickSearchClick(token, hash) {
  if (!hash) {
    return null
  }
  const data = await fetchAPI(
    `mutation($token: String!, $hash: String!) {
        tickSearchClick(token: $token, hash: $hash) {
          status
        }
      }`,
    {
      variables: { token, hash }
    },
    true
  )

  return data.tickSearchClick
}
